import { useEffect } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

const useBodyLock = (state = true) => useEffect(() => {
  if (state) disableBodyScroll();
  else enableBodyScroll();
  return enableBodyScroll;
}, [state]);

export default useBodyLock;
