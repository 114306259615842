import React from 'react';
import Helmet from 'react-helmet';
import styled, { ThemeProvider } from 'styled-components';
import { config } from 'react-spring';
import { Transition } from 'react-spring/renderprops'


import themeObject from 'styles/theme';
import fullScreen from 'styles/mixins/fullScreen';
import Navbar from './Navbar';
import Footer from './Footer';

const StyledWrapper = styled.div`
  min-height: 100vh;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.color.black};
  main {
  flex: 1;
  ${fullScreen}
    ${({ theme }) => theme.mq.m} {
      padding-top: ${({ theme }) => theme.navbarHeight.m}px;
    }
  }
`;

const Layout = ({ children, subtitle, location }) => (
  <ThemeProvider theme={themeObject}>
    <StyledWrapper>
      <Helmet title={`ONMOVE | PLUS SIZE MODEL MANAGEMENT | Warsaw | ${subtitle}`} />
      <Navbar path={location.pathname} />
      <Transition
        config={config.molasses}
        keys={location.pathname}
        from={{ opacity: 0 }}
        enter={{ opacity: 1 }}
        leave={{ opacity: 0 }}
      >
        {() => style => (
          <main style={style}>
            {children}
          </main>
        )}
      </Transition>
      <Footer />
    </StyledWrapper>
  </ThemeProvider>
);

Layout.defaultProps = {
  subtitle: 'ONMOVE',
};

export default Layout;
