import React from 'react';
import styled from 'styled-components';
import Fb from 'assets/icons/fb.svg';
import Ig from 'assets/icons/ig.svg';
import repeatedText from "../../helpers/repeatedText";

// TODO: API
const fbLink = 'https://www.facebook.com/Onmove-1872359326218713/?modal=admin_todo_tour';
const igLink = 'https://www.instagram.com/onmovemodels/';

const StyledWrapper = styled.footer`
  display: grid;
  justify-items: center;
  grid-gap: 30px;
  margin: 20px 0;
  position: relative;
  z-index: 0;
  overflow: hidden;
`;

const StyledIconsWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 1rem;
`;
const StyledTextWrapper = styled.div`
  overflow: hidden;
  width: 100vw;
  max-width: 1170px;
  box-sizing: border-box;
  div {
    letter-spacing: 4px;
    font-size: ${({ theme }) => theme.font.s};
    font-family: ${({ theme }) => theme.font.serif};
    width: 90000px;
    ${({ theme }) => theme.mq.s} {
      font-size: ${({ theme }) => theme.font.xs};
    }
  }
`;

const StyledIcon = styled.div`
  height: 24px;
  width: 24px;
  box-sizing: content-box;
  padding: 4px;
  transition: .3s ease;
  transition-delay: .15s;
  fill: ${({ theme }) => theme.color.black};
  &:hover {
    transition-delay: 0s;
    fill: ${({ theme }) => theme.color.grayLighter};
  }
`;

const Icon = ({ component, to, label }) => (
  <a aria-label={label} href={to} target="_blank" rel="noreferrer noopener">
    <StyledIcon as={component} />
  </a>
);

const Footer = () => (
  <StyledWrapper>
    <StyledIconsWrapper>
      <Icon label="Facebook" component={Fb} to={fbLink} />
      <Icon label="Instagram" component={Ig} to={igLink} />
    </StyledIconsWrapper>
    <StyledTextWrapper>
      <div>
        {repeatedText('ONMOVE\xa0AGENCJA\xa0MODELEK\xa0', 238, 1170)}
      </div>
    </StyledTextWrapper>
  </StyledWrapper>
);

export default Footer;
