import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import urls from 'config/urls';
import Logo from 'assets/icons/logo.svg';
import useBodyLock from 'helpers/useBodyLock';
import Navigation from './Navigation';
import Hamburger from './Hamburger';

const StyledNavbar = styled.div`
  display: grid;
  width: 100%;
  grid-gap: 40px;
  padding: 30px 0 20px;
  justify-items: center;
  background-color: ${({ theme }) => theme.color.white};
  z-index: 200;
  max-width: ${({theme}) => theme.container.l}px;
  margin: 0 auto;
  grid-template-columns: auto 1fr;
  ${({ theme }) => theme.mq.l} { 
    grid-gap: 20px;
  }
  ${({ theme }) => theme.mq.m} {
    position: fixed;
    padding: 1rem;
    grid-gap: 0;
    box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.05);
  }
`;

const StyledLogo = styled(Logo)`
  z-index: 100;
  height: 60px;
  padding: 0 30px;
  ${({ theme }) => theme.mq.l} { 
    height: 50px;
    padding: 0 20px;
  }
  ${({ theme }) => theme.mq.m} { 
    height: 45px;
    padding: 0 10px;
  }
`;

const Navbar = ({ path}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  useBodyLock(isMenuOpen);

  const toggleMenu = useCallback(() => {
    setIsMenuOpen(prevMenuState => !prevMenuState);
  }, [setIsMenuOpen]);

  const closeMenu = useCallback(() => {
    setIsMenuOpen(false);
  }, [setIsMenuOpen]);
  return (
    <StyledNavbar>
        <Link to={urls.HOME}>
          <StyledLogo />
        </Link>
        <Hamburger isMenuOpen={isMenuOpen} onClick={toggleMenu} />
      <Navigation path={path} isMenuOpen={isMenuOpen} closeMenu={closeMenu} />
    </StyledNavbar>
  );
};

export default Navbar;
