import React from 'react';
import styled from 'styled-components';
import { animated, useSpring, config } from 'react-spring';
import themeObject from 'styles/theme';


const StyledButton = styled.button`
  display: none;
  background: none;
  border: none;
  padding: 7px 30px;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  z-index: 100;
  justify-self: right;
  ${({ theme }) => theme.mq.m} { display: flex }
  &:focus {
    border: none;
    outline: none;
  }
`;

const openedConfig = {
  top: 'translate(0, 3) rotate(0)',
  center: 'translate(0, 14) rotate(0) scale(1)',
  bottom: 'translate(0, 26) rotate(0)',
};

const closedConfig = {
  top: 'translate(4, 25) rotate(-45)',
  center: 'translate(20, 14) rotate(0) scale(0)',
  bottom: 'translate(5, 4) rotate(45)',
};

const rectProps = {
  width: '30',
  height: '1',
  fill: themeObject.color.black,
};

const Hamburger = ({ isMenuOpen, ...rest }) => {
  const {
    top, center, bottom,
  } = useSpring({
    to: isMenuOpen ? closedConfig : openedConfig,
    config: config.default,
  });
  return (
    <StyledButton type="button" aria-label="open-navigation" {...rest}>
      <svg width="30" height="30" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
        <animated.rect {...rectProps} transform={top} />
        <animated.rect {...rectProps} transform={center} />
        <animated.rect {...rectProps} transform={bottom} />
      </svg>
    </StyledButton>
  );
};

export default Hamburger;
