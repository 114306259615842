const mq = {
  l: 1315,
  m: 980,
  s: 680,
  xs: 420,
  xxs: 360,
};
// v3
const hexToRgba = (hex, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
  return `rgba(${r},${g},${b},${alpha})`;
};

const createMq = value => `@media(max-width: ${value}px)`;
export { mq, hexToRgba, createMq };
export default {
  color: {
    grayLight: '#C2C2C2',
    grayLighter: '#a3a3a3',
    gray: '#444444',
    grayDark: '#2c2c2c',
    grayGreen: '#A8A085',
    white: '#ffffff',
    black: '#0a0a0a',
  },
  font: {
    xs: '.8rem',
    s: '1rem',
    m: '1.2rem',
    l: '1.5rem',
    xl: '1.6rem',
    serif: '"Lora", serif',
    sansSerif: '"Montserrat", Helvetica, Arial, sans-serif',
  },
  mq: {
    l: createMq(mq.l),
    m:  createMq(mq.m),
    s: createMq(mq.s),
    xs: createMq(mq.xs),
    xxs: createMq(mq.xxs),
  },
  container: {
    xs: 520,
    s: 680,
    m: 980,
    l: 1170,
    max: 2600,
  },
  navbarHeight: {
    s: 68,
    m: 68,
    l: 113,
  },
  footerHeight: 116,
};
