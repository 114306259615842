import React from 'react';
import styled from 'styled-components';
import {Link} from 'gatsby';

import urls from 'config/urls';
import NestedLink from "../NestedLink";

const StyledNav = styled.nav`
  display: flex;
  width: 100%;
  justify-content: center;
  transition: transform .45s ease, opacity .2s ease .15s;

  ${({theme}) => theme.mq.m} {
    left: 0;
    bottom: 0;
    position: fixed;
    transform: ${({isMenuOpen}) => `translateY(${isMenuOpen ? '0' : '100%'})`};
    opacity: ${({isMenuOpen}) => ( isMenuOpen ? 1 : 0 )};
    height: ${({theme}) => `calc(100vh - ${theme.navbarHeight.m}px + 5px)`};
    width: 100%;
    flex-direction: column;
    justify-content: center;
    background-color: ${({theme}) => theme.color.white};
    z-index: 50;
  }
`;

const StyledLink = styled(Link)`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  height: 45px;
  text-transform: uppercase;
  text-decoration: none;
  font-size: ${({theme}) => theme.font.xl};
  color: ${({theme}) => theme.color.grayLighter};
  white-space: nowrap;
  margin: 0 14px;
  transition: box-shadow .3s ease, color .3s ease;
  box-shadow: inset 0px 0px 0px 0px #aaa;

  &:hover {
    color: ${({theme}) => theme.color.black};
    box-shadow: inset 0px 4px 0px 0px #000;
  }

  &.active {
    color: ${({theme}) => theme.color.black};
    box-shadow: inset 0px 4px 0px 0px #000;
  }

  ${({theme}) => theme.mq.l} {
    font-size: ${({theme}) => theme.font.l};
    margin: 0 12px;
  }

  ${({theme}) => theme.mq.m} {
    transition: opacity .45s ease .5s;
    opacity: ${({isMenuOpen}) => ( isMenuOpen ? 1 : 0 )};
    width: 100%;
    max-width: 100%;
    max-height: 60px;
    box-shadow: none;
    margin: 0;
    transition-delay: ${({index}) => index * .1}s;

    &:hover {
      box-shadow: none;
      color: ${({theme}) => theme.color.grayLighter};
    }

    &.active {
      box-shadow: none;
      color: ${({theme}) => theme.color.black};
    }

    &.active:hover {
      box-shadow: none;
      color: ${({theme}) => theme.color.black};
    }
  }
`;

const menuLinks = [
  {
    to: urls.HOME,
    label: 'home',
  },
  {
    to: urls.MODELS,
    label: 'models',
    children: [
      {
        to: urls.MODELS_MAIN,
        label: 'main',
      },
      {
        to: urls.MODELS_NEW,
        label: 'new face',
      }
    ]
  },
  {
    to: urls.NEWS,
    label: 'news',
  },
  {
    to: urls.PRODUCTION,
    label: 'production',
    external: true
  },
  {
    to: urls.BECOME_MODEL,
    label: 'become a model',
  },
  {
    to: urls.CONTACT,
    label: 'contact',
  },
];

const Navigation = ({isMenuOpen, path}) => (
  <StyledNav isMenuOpen={isMenuOpen}>
    {
      menuLinks.map(({to, label, external, children}, index) => {
        if(children) {
          return <NestedLink path={path} to={to} label={label} children={children} isMenuOpen={isMenuOpen} index={index} />
        }
          return (
            external ? (
              <StyledLink
                index={index}
                target="_blank"
                as="a"
                href={to}
                activeClassName="active"
                key={label}
                isMenuOpen={isMenuOpen}
              >
                {label}
              </StyledLink>
            ) : (
              <StyledLink index={index} to={to} activeClassName="active" key={label} isMenuOpen={isMenuOpen}>
                {label}
              </StyledLink>
            )
          )
        }
      )
    }
  </StyledNav>
);

export default Navigation;
