import React, {useState} from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";
import {Link} from "gatsby";

const StyledNestedLink = styled(Link)`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  height: 45px;
  text-transform: uppercase;
  text-decoration: none;
  font-size: ${({theme}) => theme.font.xl};
  color: ${({theme}) => theme.color.grayLighter};
  white-space: nowrap;
  margin: 0 14px;
  transition: color .3s ease;

  &:hover {
    color: ${({theme}) => theme.color.black};
  }

  &.active {
    color: ${({theme}) => theme.color.black};
  }

  ${({theme}) => theme.mq.l} {
    font-size: ${({theme}) => theme.font.l};
    margin: 0 12px;
  }

  ${({theme}) => theme.mq.m} {
    transition: opacity .45s ease .5s;
    opacity: ${({isMenuOpen}) => ( isMenuOpen ? .75 : 0 )};
    font-size: 1.25rem;
    transition-delay: ${({index}) => index * .1}s;
    flex-grow: 0;

    &:hover {
      box-shadow: none;
      color: ${({theme}) => theme.color.grayLighter};
    }

    &.active {
      box-shadow: none;
      color: ${({theme}) => theme.color.black};
    }

    &.active:hover {
      box-shadow: none;
      color: ${({theme}) => theme.color.black};
    }
  }
`;

const StyledLink = styled(Link)`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  height: 45px;
  text-transform: uppercase;
  text-decoration: none;
  font-size: ${({theme}) => theme.font.xl};
  color: ${({isActive, theme}) => isActive ? theme.color.black : theme.color.grayLighter};
  white-space: nowrap;
  margin: 0 14px;
  transition: box-shadow .3s ease, color .3s ease;
  box-shadow: ${({isActive}) => isActive ? 'inset 0px 4px 0px 0px #000' : 'inset 0px 0px 0px 0px #aaa'};

  &:hover {
    color: ${({theme}) => theme.color.black};
    box-shadow: inset 0px 4px 0px 0px #000;
  }

  &.active {
    color: ${({theme}) => theme.color.black};
    box-shadow: inset 0px 4px 0px 0px #000;
  }

  ${({theme}) => theme.mq.l} {
    font-size: ${({theme}) => theme.font.l};
    margin: 0 12px;
  }

  ${({theme}) => theme.mq.m} {
    transition: opacity .45s ease .5s;
    opacity: ${({isMenuOpen}) => ( isMenuOpen ? 1 : 0 )};
    width: 100%;
    max-width: 100%;
    max-height: 60px;
    box-shadow: none;
    margin: 0;
    transition-delay: ${({index}) => index * .1}s;

    &:hover {
      box-shadow: none;
      color: ${({theme}) => theme.color.grayLighter};
    }

    &.active {
      box-shadow: none;
      color: ${({theme}) => theme.color.black};
    }

    &.active:hover {
      box-shadow: none;
      color: ${({theme}) => theme.color.black};
    }
  }
`;

const StyledChildrenWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 90%;
  transform: translate3d(-50%, -120%, 0) scale(.5);
  display: flex;
  opacity: 0;
  transition: .3s ease-in-out;

  ${({theme}) => theme.mq.m} {
    opacity: 1;
    position: static;
    transform: translateX(10px);
    width: 150px;
    margin: 0 auto;
    justify-content: space-evenly;
  }
`

const StyledWrapper = styled.div`
  flex-grow: 1;
  position: relative;
  height: 45px;

  &:hover {
    ${StyledChildrenWrapper} {
      opacity: 1;
      transform: translate3d(-45%, 10%, 0) scale(1);
    }

    ${StyledLink} {
      color: ${({theme}) => theme.color.black};
      box-shadow: inset 0 4px 0 0 #000;

      ${({theme}) => theme.mq.m} {
        color: ${({theme}) => theme.color.grayLighter};
        box-shadow: none;
      }
    }
  }

  ${({theme}) => theme.mq.m} {
    max-width: 100%;
    max-height: 90px;
    margin: 0;
  }
`;

const NestedLink = ({to, label, children, path, isMenuOpen, index}) => {

  return (
    <StyledWrapper>
      <StyledLink isActive={path.includes(to)} to={to} isMenuOpen={isMenuOpen} index={index}>{label}</StyledLink>
      <StyledChildrenWrapper>
        {children.map(({to, label}) => (
          <StyledNestedLink to={to} isMenuOpen={isMenuOpen} index={index}>{label}</StyledNestedLink>
        ))}
      </StyledChildrenWrapper>
    </StyledWrapper>
  );
}

NestedLink.defaultProps = {};

NestedLink.propTypes = {};

export default NestedLink;